// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import { DATA_STATE } from '../../dataState';
import { USER_ACTION_TYPES } from '../actions';

const INITIAL_STATE = {
	dataState: DATA_STATE.NOT_INITIALIZED
};

export default function reducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case USER_ACTION_TYPES.SET_PATIENT_MODE:
			return {
				...state,
				dataState: DATA_STATE.INITIALIZED,
				enabled: action.enabled
			};

		default:
			return state;
	}
}

export const getPatientModeEnabledFromState = state => state.enabled;
