// Copyright 2018-2023 NXGN Management, LLC. All Rights Reserved.

import createFailedNetworkActionCreator from '../../utils/createFailedNetworkActionCreator';

export const USER_ACTION_TYPES = {
	FETCHING: 'USER/FETCHING',
	FETCH_SUCCEEDED: 'USER/FETCH_SUCCEEDED',
	FETCH_FAILED: 'USER/FETCH_FAILED',
	SET_PATIENT_MODE: 'USER/PATIENT_MODE',
	GUEST_TOKEN_REFRESHED: 'USER/GUEST_TOKEN_REFRESHED',
	SET_NEXTGEN_PRACTICE_CONFIG: 'USER/SET_NEXTGEN_PRACTICE_CONFIG',
	SET_NEXTGEN_PRACTICE_CONFIG_FAILED: 'USER/SET_NEXTGEN_PRACTICE_CONFIG_FAILED',
	SET_GUEST_KEY: 'SET_GUEST_KEY'
};

export function fetchingUser() {
	return {
		type: USER_ACTION_TYPES.FETCHING
	};
}

export function fetchUserSucceeded(expires, token, config) {
	return {
		type: USER_ACTION_TYPES.FETCH_SUCCEEDED,
		expires,
		token,
		config
	};
}

export function guestTokenRefreshed(expires, token) {
	return {
		type: USER_ACTION_TYPES.GUEST_TOKEN_REFRESHED,
		expires,
		token
	};
}

export function setPatientMode(enabled) {
	return {
		type: USER_ACTION_TYPES.SET_PATIENT_MODE,
		enabled
	};
}

export function setNextgenPracticeConfig(response){
	return {
		type: USER_ACTION_TYPES.SET_NEXTGEN_PRACTICE_CONFIG,
		response
	}
}
export function setGuestKey(response){
	return{
		type: USER_ACTION_TYPES.SET_GUEST_KEY,
		response
	}
}
export const fetchUserFailed = createFailedNetworkActionCreator(USER_ACTION_TYPES.FETCH_FAILED);
export const fetchNextgenPracticeConfigFailed = createFailedNetworkActionCreator(USER_ACTION_TYPES.SET_NEXTGEN_PRACTICE_CONFIG_FAILED);
