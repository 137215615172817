// Copyright 2018-2023 NXGN Management, LLC. All Rights Reserved.

import axios from 'axios';
import { CONFIG } from '../config';
import { createAxiosConfigWithAuthHeader } from '../utils';

export function getToken(dob, encryptedToken, zip) {
	const encodedToken = window.encodeURIComponent(encryptedToken);

	return axios.post(CONFIG.BASE_URL + `/v1/guestSessions/session?token=${encodedToken}`, {
		dob,
		zip
	});
}

export function authorize(authToken) {
	return axios.get(
		CONFIG.BASE_URL + '/v1/guestSessions/authorization',
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function getGuestKey() {
	return axios.get(
		'/assets/precheck-app/config.json'
	);
}
