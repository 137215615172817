// Copyright 2023 NXGN Management, LLC. All Rights Reserved.

import axios from 'axios';
import {PING_TOKEN} from "../../../constants";

var getCookie = function (name) {
	var re = new RegExp(name + '=([^;]+)');
	var value = re.exec(document.cookie);
	return value != null ? unescape(value[1]) : null;
};
axios.interceptors.request.use(
	(config) => config,
	(error) => Promise.reject(error)
);

const maxRetries = 2;
const retryDelay = 1000;
export const interceptor = () =>

	axios.interceptors.response.use(
		(response) => {
			try {
				const pingToken = getCookie(PING_TOKEN);
				if (pingToken) {
					sessionStorage.setItem(PING_TOKEN, pingToken);
				}
			} catch (error) {
				return Promise.reject(error);
			}
			return response;
		},
		(error) => {
			const {config, response: {status}} = error;
			const isNotAuthorize = status === 401;

			if (isNotAuthorize) {
				let retryCount = config.retryCount || 0;

				if (retryCount < maxRetries) {
					retryCount++;
					const retryConfig = {
						...config,
						retryCount,
					};

					return new Promise((resolve) => setTimeout(() => resolve(axios(retryConfig)), retryDelay));
				}
			}

			return Promise.reject(error);
		}
	)
