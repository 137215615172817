// Copyright 2020 NXGN Management, LLC. All Rights Reserved.

import { ARRIVAL_ACTION_TYPES } from './action';
import { DATA_STATE } from '../dataState';

const INITIAL_STATE = {
	dataState: DATA_STATE.NOT_INITIALIZED
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case ARRIVAL_ACTION_TYPES.CONFIRMING_ARRIVAL:
			return {
				...state,
				dataState: DATA_STATE.SENDING
			};
		case ARRIVAL_ACTION_TYPES.ARRIVAL_SUCCESS:
			return {
				...state,
				dataState: DATA_STATE.INITIALIZED,
				...action.data
			};

		case ARRIVAL_ACTION_TYPES.ARRIVAL_FAILURE:
			return {
				...state,
				dataState: DATA_STATE.SEND_FAILED
			};
		default:
			return state;
	}
}
