// Copyright 2023 NXGN Management, LLC. All Rights Reserved.

import axios from 'axios';

import { CONFIG } from '../config';
import { createAxiosConfigWithAuthHeader } from '../utils';

export function getNextgenPracticeDetails(authToken, practiceId, pmPatientId, integrationId) {
	return axios.get(
		CONFIG.BASE_URL +
		`/v1/guestSessions/sitegenPracticeDtls/practices/${practiceId}/patients/${pmPatientId}/integrations/${integrationId}`,
		createAxiosConfigWithAuthHeader(authToken)
	);
}
