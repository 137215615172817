// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import { DATA_STATE } from '../dataState';
import { COPAY_ACTION_TYPES } from './actions';

const INITIAL_STATE = {
	dataState: DATA_STATE.NOT_INITIALIZED
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case COPAY_ACTION_TYPES.REENTERING_CARD_DETAILS:
			return INITIAL_STATE;
		case COPAY_ACTION_TYPES.SENDING:
			return { dataState: DATA_STATE.SENDING };
		case COPAY_ACTION_TYPES.SEND_SUCCEEDED:
			return { dataState: DATA_STATE.INITIALIZED };
		case COPAY_ACTION_TYPES.SEND_FAILED:
			return { dataState: DATA_STATE.SEND_FAILED, errorType: action.errorType };
		case COPAY_ACTION_TYPES.REMOVE_SEND_FAILED:
			return {
				...state,
				dataState: INITIAL_STATE
			};
		default:
			return state;
	}
}
