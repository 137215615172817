// Copyright 2018-2021 NXGN Management, LLC. All Rights Reserved.

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { getLocaleStateFromState } from '../../services/redux/rootReducer';
import enMessages from '../../translations/en';
import esMessages from '../../translations/es';
import { setLocale } from '../../services/redux/locale/actions';

/* eslint-disable no-unused-expressions */
// IE11 compatibility
const addLocaleData = locales => {
	if (!Intl.PluralRules) {
		import('@formatjs/intl-pluralrules/polyfill');
		for (const locale of locales) {
			import('@formatjs/intl-pluralrules/dist/locale-data/' + locale);
		}
	}
	if (!Intl.RelativeTimeFormat) {
		import('@formatjs/intl-relativetimeformat/polyfill');
		for (const locale of locales) {
			import('@formatjs/intl-relativetimeformat/dist/locale-data/' + locale);
		}
	}
};

addLocaleData(['en', 'es']);
const MESSAGES = {
	'en-US': enMessages,
	'es-US': esMessages
};

class ConnectedIntlProvider extends Component {
	static propTypes = {
		localeState: PropTypes.shape({
			locale: PropTypes.string.isRequired,
			loaded: PropTypes.bool
		}).isRequired,
		children: PropTypes.node,
		setLocale: PropTypes.func.isRequired
	};

	componentDidMount() {
		const { localeState, setLocale } = this.props;

		if (localeState.loaded) {
			// If older version value without region, add the region
			const locale = localeState.locale;
			if (locale === 'en' || locale === 'es') {
				setLocale(locale + '-US');
			}
		} else {
			const language = this.guessLanguage();
			setLocale(language + '-US');
		}
	}

	guessLanguage() {
		const SUPPORTED_LANGUAGES = ['en', 'es'];
		const language = (navigator.language || navigator.browserLanguage).split(/[-_]/)[0];
		if (!SUPPORTED_LANGUAGES.find(lang => lang === language)) {
			return 'en';
		}
		return language;
	}

	render() {
		const { locale } = this.props.localeState;
		return (
			<IntlProvider locale={locale} messages={MESSAGES[locale]}>
				{this.props.children}
			</IntlProvider>
		);
	}
}

const mapStateToProps = state => ({
	localeState: getLocaleStateFromState(state)
});

export default connect(
	mapStateToProps,
	{ setLocale }
)(ConnectedIntlProvider);
