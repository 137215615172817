// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

export const CONFIRMATION_ACTION_TYPES = {
	CONFIRMING_APPOINTMENT: 'CONFIRMING_APPOINTMENT',
	APPOINTMENT_CONFIRMATION_SUCCESS: 'APPOINTMENT_CONFIRMATION_SUCCESS',
	APPOINTMENT_CONFIRMATION_FAILURE: 'APPOINTMENT_CONFIRMATION_FAILURE'
};

export function confirmingAppointment() {
	return {
		type: CONFIRMATION_ACTION_TYPES.CONFIRMING_APPOINTMENT
	};
}

export function appointmentConfirmationSuccess(data) {
	return {
		type: CONFIRMATION_ACTION_TYPES.APPOINTMENT_CONFIRMATION_SUCCESS,
		data
	};
}

export function appointmentConfirmationFailure() {
	return {
		type: CONFIRMATION_ACTION_TYPES.APPOINTMENT_CONFIRMATION_FAILURE
	};
}
