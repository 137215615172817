// Copyright 2018-2023 NXGN Management, LLC. All Rights Reserved.

export const ROUTES = {
	ACTION: '/action',
	BALANCE_CONFIRM: '/balance-confirm',
	BALANCE_CREDIT_CARD: '/balance-card',
	BALANCE: '/balance',
	COMPLETE_FORMS: '/complete-forms',
	COPAY_CONFIRM: '/copay-confirm',
	COPAY_CREDIT_CARD: '/copay-card',
	COPAY: '/copay',
	FINISH_PRECHECK: '/finish-precheck',
	INSURANCE_ADD: '/insurance-add',
	INSURANCE_CARDS: '/insurance',
	INSURANCE_FORM: '/insurance-form',
	INSURANCE_IMAGES: '/insurance-images',
	LOGIN: '/login',
	OPTOUT: '/optout',
	SESSION_TIMEOUT: '/session-timeout',
	SIGNED_OUT: '/signed-out',
	VERIFY_CONTACT_INFO: '/contact-info',
	VERIFY_PERSONAL_INFO: '/personal-info',
	VERIFY_PREFERRED_PHARMACY: '/pharmacy',
	VIEW_APPOINTMENT_DETAILS: '/view-appointment-details',
	VIEW_APPOINTMENTS: '/view-appointments',
	CURBS: '/curbs',
	IMH_FORMS_QNA:"/complete-forms-imh",
	BILLING: '/billing'
};
