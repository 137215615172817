import axios from 'axios';
import { CONFIG } from '../config';
import { createAxiosConfigWithAuthHeader } from '../utils';

export function getSettings(authToken, practiceId, pmPatientId) {
	return axios.get(
		CONFIG.BASE_PRACTICE_URL +
			`/v2/settings/practice/${practiceId}/patient/guest?pmPatientId=${pmPatientId}`,
		createAxiosConfigWithAuthHeader(authToken)
	);
}
