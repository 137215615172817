// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

export const OPTOUT_ACTION_TYPES = {
	REMOVING_EMAIL: 'USER/OPTOUT/REMOVING',
	REMOVED_EMAIL_SUCCESS: 'USER/OPTOUT/REMOVED_EMAIL_SUCCESS',
	REMOVED_EMAIL_FAILURE: 'USER/OPTOUT/REMOVED_EMAIL_FAILURE'
};

export function removingEmail() {
	return {
		type: OPTOUT_ACTION_TYPES.REMOVING_EMAIL
	};
}

export function removeEmailSuccess() {
	return {
		type: OPTOUT_ACTION_TYPES.REMOVED_EMAIL_SUCCESS
	};
}

export function removeEmailFailure() {
	return {
		type: OPTOUT_ACTION_TYPES.REMOVED_EMAIL_FAILURE
	};
}
