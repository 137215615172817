// Copyright 2020 NXGN Management, LLC. All Rights Reserved.

export const ARRIVAL_ACTION_TYPES = {
	CONFIRMING_ARRIVAL: 'CONFIRMING_ARRIVAL',
	ARRIVAL_SUCCESS: 'ARRIVAL_SUCCESS',
	ARRIVAL_FAILURE: 'ARRIVAL_FAILURE'
};

export function confirmingArrival() {
	return {
		type: ARRIVAL_ACTION_TYPES.CONFIRMING_ARRIVAL
	};
}

export function arrivalSuccess(data) {
	return {
		type: ARRIVAL_ACTION_TYPES.ARRIVAL_SUCCESS,
		data
	};
}

export function arrivalFailure() {
	return {
		type: ARRIVAL_ACTION_TYPES.ARRIVAL_FAILURE
	};
}
