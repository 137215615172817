// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import createFailedNetworkActionCreator from '../../utils/createFailedNetworkActionCreator';

export const COPAY_ACTION_TYPES = {
	REENTERING_CARD_DETAILS: 'COPAY/REENTERING_CARD_DETAILS',
	SENDING: 'COPAY/SENDING',
	SEND_FAILED: 'COPAY/SEND_FAILED',
	SEND_SUCCEEDED: 'COPAY/SEND_SUCCEEDED',
	REMOVE_SEND_FAILED: 'COPAY/REMOVE_SEND_FAILED'
};

export function reenteringCopayCardDetails() {
	return {
		type: COPAY_ACTION_TYPES.REENTERING_CARD_DETAILS
	};
}

export function sendingCopay() {
	return {
		type: COPAY_ACTION_TYPES.SENDING
	};
}

export function copaySucceeded() {
	return {
		type: COPAY_ACTION_TYPES.SEND_SUCCEEDED
	};
}

export function removeSendFailed() {
	return {
		type: COPAY_ACTION_TYPES.REMOVE_SEND_FAILED
	};
}

export const copayFailed = createFailedNetworkActionCreator(COPAY_ACTION_TYPES.SEND_FAILED);
