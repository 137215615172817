// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import { UPCOMING_APPOINTMENTS_ACTION_TYPES } from './actions';
import { DATA_STATE } from '../dataState';
import { GLOBAL_ACTION_TYPES } from '../actions';

const INITIAL_STATE = {
	dataState: DATA_STATE.NOT_INITIALIZED
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case UPCOMING_APPOINTMENTS_ACTION_TYPES.FETCHING:
			return {
				...state,
				dataState: DATA_STATE.FETCHING
			};
		case UPCOMING_APPOINTMENTS_ACTION_TYPES.FETCH_FAILED:
			return {
				...state,
				dataState: DATA_STATE.FETCH_FAILED,
				errorType: action.errorType
			};
		case UPCOMING_APPOINTMENTS_ACTION_TYPES.FETCH_SUCCEEDED:
			return {
				dataState: DATA_STATE.INITIALIZED,
				appointments: action.appointments
			};
		case GLOBAL_ACTION_TYPES.RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
}
