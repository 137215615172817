// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import { DATA_STATE } from '../../dataState';
import { ERROR } from '../../errorTypes';
import { USER_ACTION_TYPES } from '../actions';
import { GLOBAL_ACTION_TYPES } from '../../actions';
import * as moment from 'moment';

const INITIAL_STATE = {
	dataState: DATA_STATE.NOT_INITIALIZED
};

export default function reducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case USER_ACTION_TYPES.FETCHING:
			return {
				...state,
				dataState: DATA_STATE.FETCHING
			};
		case USER_ACTION_TYPES.FETCH_SUCCEEDED:
		case USER_ACTION_TYPES.GUEST_TOKEN_REFRESHED:
			return {
				dataState: DATA_STATE.INITIALIZED,
				expires: action.expires,
				token: action.token
			};
		case USER_ACTION_TYPES.FETCH_FAILED:
			return {
				...state,
				dataState: DATA_STATE.FETCH_FAILED,
				errorType: action.errorType
			};
		case GLOBAL_ACTION_TYPES.RESET:
			return {
				dataState: DATA_STATE.SIGNED_OUT
			};
		default:
			if (action.errorType === ERROR.UNAUTHORIZED) {
				return {
					dataState: DATA_STATE.NOT_INITIALIZED
				};
			}
			return state;
	}
}

export const hasValidToken = token =>
	token.expires ? moment(moment.now()).isBefore(token.expires) : false;
export const hasUserSignedOut = token => token.dataState === DATA_STATE.SIGNED_OUT;
