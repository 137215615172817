// Copyright 2018-2023 NXGN Management, LLC. All Rights Reserved.

import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { PERSISTENCE_KEYS } from '../persistence/persistenceKeys';
import appointmentsReducer, * as fromAppointments from './appointments/reducer';
import balanceReducer, * as fromBalance from './balance/reducer';
import confirmationReducer from './confirmation/reducer';
import copayReducer from './copay/reducer';
import creditCardsReducer, * as fromCreditCards from './creditCards/reducer';
import insuranceReducer from './insurance/reducer';
import insuranceSelectionReducer from './insuranceSelection/reducer';
import localeReducer, * as fromLocale from './locale/reducer';
import optoutReducer from './optout/reducer';
import patientFormsReducer from './patientForms/reducer';
import upcomingAppointmentsReducer from './upcomingAppointments/reducer';
import user, * as fromUser from './user';
import confirmArrivalReducer from './arrival/reducer';

const createRootReducer = history =>
	combineReducers({
		router: connectRouter(history),
		appointments: appointmentsReducer,
		balance: balanceReducer,
		confirmation: confirmationReducer,
		copay: copayReducer,
		creditCards: creditCardsReducer,
		insurance: insuranceReducer,
		insuranceSelection: insuranceSelectionReducer,
		locale: localeReducer,
		optout: optoutReducer,
		patientForms: patientFormsReducer,
		upcomingAppointments: upcomingAppointmentsReducer,
		user,
		arrival: confirmArrivalReducer
	});
export default createRootReducer;

// ---------
// selectors
export const getAppointmentsStateFromState = state => state.appointments;
export const getBalanceStateFromState = state => state.balance;
export const getConfirmationStateFromState = state => state.confirmation;
const getCreditCardsStateFromState = state => state.creditCards;
export const getCopayStateFromState = state => state.copay;
export const getInsuranceStateFromState = state => state.insurance;
export const getInsuranceSelectionFromState = state => state.insuranceSelection;
export const getLocaleStateFromState = state => state.locale;
export const getUserStateFromState = state => state.user;
export const getRouterFromState = state => state.router;
export const getPatientFormsStateFromState = state => state.patientForms;
export const getOptoutStateFromState = state => state.optout;
export const getUpcomingAppointmentsStateFromState = state => state.upcomingAppointments;

export const getConfigStateFromState = state =>
	fromUser.getConfigFromUserState(getUserStateFromState(state));
export const getTokenStateFromState = state =>
	fromUser.getTokenFromUserState(getUserStateFromState(state));
export const hasValidToken = state => fromUser.hasValidToken(getUserStateFromState(state));
export const hasSignedOut = state => fromUser.hasSignedOut(getUserStateFromState(state));
export const getEnabledStepKeysFromState = state =>
	fromUser.getEnabledStepKeys(getUserStateFromState(state));
export const getAllowedCardTypesFromState = state =>
	fromUser.getAllowedCardTypesFromUserState(getUserStateFromState(state));
export const getPatientModeEnabledFromState = state =>
	fromUser.getPatientModeEnabledFromUserState(getUserStateFromState(state));
export const getEnabledPagesFromState = state =>
	fromUser.getEnabledPages(getUserStateFromState(state));
export const getDemographicSettingsFromState = state =>
	fromUser.getPracticeDemographicSettings(getUserStateFromState(state));

export const getEncryptedIdentifierFromState = state =>
	fromAppointments.getEncryptedIdentifier(getAppointmentsStateFromState(state));
export const getSelectedAppointmentFromState = state =>
	fromAppointments.getSelectedAppointment(getAppointmentsStateFromState(state));
export const getPrecheckAppointmentFromState = state =>
	fromAppointments.getPrecheckAppointment(getAppointmentsStateFromState(state));
export const getLocaleFromState = state => fromLocale.getLocale(getLocaleStateFromState(state));
export const getAllCreditCardsFromState = state =>
	fromCreditCards.getAllCreditCards(getCreditCardsStateFromState(state));
export const getSelectedCreditCardFromState = state =>
	fromCreditCards.getSelectedCreditCard(getCreditCardsStateFromState(state));
export const getPracticeIdFromState = state =>
	fromUser.getPracticeIdFromUserState(getUserStateFromState(state));
export const getPracticeNameFromState = state =>
	fromUser.getPracticeNameFromUserState(getUserStateFromState(state));
export const getPracticePhoneFromState = state =>
	fromUser.getPracticePhoneFromUserState(getUserStateFromState(state));
export const getPracticeTimezoneFromState = state =>
	fromUser.getPracticeTimezoneFromUserState(getUserStateFromState(state));
export const getPracticePatientModeCompletionMessageFromState = state =>
	fromUser.getPracticePatientModeCompletionMessageFromUserState(getUserStateFromState(state));
export const getBalancePaymentAmountFromState = state =>
	fromBalance.getPaymentAmountFromBalanceState(getBalanceStateFromState(state));
export const getMinBalanceFromState = state =>
	fromUser.getMinBalanceFromUserState(getUserStateFromState(state));
export const getPracticeInsuranceMessagesFromState = state =>
	fromUser.getPracticeInsuranceMessagesFromUserState(getUserStateFromState(state));
export const getPracticeInsuranceSettingsFromState = state =>
	fromUser.getPracticeInsuranceSettingsFromUserState(getUserStateFromState(state));
export const getPatientProvidedInsuranceFromSelectedAppt = state =>
	fromAppointments.getPatientProvidedInsurance(getSelectedAppointmentFromState(state));
export const getPracticeNotifySettingsFromState = state =>
	fromUser.getPracticeNotifySettingsFromUserState(getUserStateFromState(state));
export const getArrivalStateFromState = state => state.arrival;

// -----------
// persistence
export const createStateFromPersistedValues = values => {
	if (values === undefined) {
		return undefined;
	}

	const appointmentId = values[PERSISTENCE_KEYS.APPOINTMENT_ID];
	const balanceAmount = values[PERSISTENCE_KEYS.BALANCE_AMOUNT];
	const encryptedIdentifier = values[PERSISTENCE_KEYS.ENCRYPTED_IDENTIFIER];
	const insuranceSelection = values[PERSISTENCE_KEYS.INSURANCE_SELECTION];
	const locale = values[PERSISTENCE_KEYS.LOCALE];
	const user = values[PERSISTENCE_KEYS.USER_STATE];

	return {
		appointments: fromAppointments.createStateFromSelectedAppointmentId(
			appointmentId,
			encryptedIdentifier
		),
		balance: fromBalance.createStateFromBalanceAmount(balanceAmount),
		insuranceSelection,
		locale: fromLocale.createLoadedLocaleState(locale),
		user
	};
};
export const getSelectedAppointmentIdFromState = state =>
	fromAppointments.getSelectedAppointmentId(getAppointmentsStateFromState(state));
