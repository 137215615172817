// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import { combineReducers } from 'redux';
import omit from 'lodash/omit';
import { CREDIT_CARDS_ACTION_TYPES } from './actions';

function allNumbers(state = [], action) {
	switch (action.type) {
		case CREDIT_CARDS_ACTION_TYPES.ADD:
			return state.includes(action.card.cardNumber)
				? state
				: [...state, action.card.cardNumber];
		case CREDIT_CARDS_ACTION_TYPES.REMOVE:
			return state.filter(card => card !== action.selectedCard.cardNumber);
		default:
			return state;
	}
}

function cards(state = {}, action) {
	switch (action.type) {
		case CREDIT_CARDS_ACTION_TYPES.ADD:
			return {
				...state,
				[action.card.cardNumber]: action.card
			};
		case CREDIT_CARDS_ACTION_TYPES.REMOVE:
			omit(state, action.selectedCard);
			return state;
		default:
			return state;
	}
}

function selected(state = null, action) {
	switch (action.type) {
		case CREDIT_CARDS_ACTION_TYPES.ADD:
			return action.card.cardNumber;
		case CREDIT_CARDS_ACTION_TYPES.SELECT:
			return action.cardNumber;
		case CREDIT_CARDS_ACTION_TYPES.REMOVE:
			return {};
		default:
			return state;
	}
}

export default combineReducers({
	allNumbers,
	cards,
	selected
});

export const getSelectedCreditCard = creditCardsState =>
	creditCardsState.selected && creditCardsState.cards[creditCardsState.selected];

export const getAllCreditCards = creditCardsState =>
	creditCardsState.allNumbers.map(number => creditCardsState.cards[number]);
