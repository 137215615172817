// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import { APPOINTMENTS_ACTION_TYPES } from '../appointments/actions';
import { DATA_STATE } from '../dataState';
import { PATIENT_FORMS_ACTIONS_TYPES } from './actions';

const INITIAL_STATE = {
	dataState: DATA_STATE.NOT_INITIALIZED
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case APPOINTMENTS_ACTION_TYPES.SELECT:
			if (action.id === state.selectedAppointmentId) {
				return state;
			}
			// Invalidate patient forms store. Next fetch will be for this appt ID.
			return {
				dataState: DATA_STATE.NOT_INITIALIZED,
				selectedAppointmentId: action.id
			};
		case PATIENT_FORMS_ACTIONS_TYPES.FETCHING:
			return {
				...state,
				dataState: DATA_STATE.FETCHING
			};
		case PATIENT_FORMS_ACTIONS_TYPES.FETCH_SUCCEEDED:
			return {
				dataState: DATA_STATE.INITIALIZED,
				patientForms: action.patientForms
			};
		case PATIENT_FORMS_ACTIONS_TYPES.FETCH_FAILED:
			return {
				...state,
				dataState: DATA_STATE.FETCH_FAILED,
				errorType: action.errorType
			};
		case PATIENT_FORMS_ACTIONS_TYPES.SENDING:
			return {
				...state,
				dataState: DATA_STATE.SENDING
			};
		case PATIENT_FORMS_ACTIONS_TYPES.SEND_SUCCEEDED:
			return {
				dataState: DATA_STATE.INITIALIZED,
				patientForms: action.patientForms
			};
		case PATIENT_FORMS_ACTIONS_TYPES.SEND_FAILED:
			return {
				...state,
				dataState: DATA_STATE.SEND_FAILED,
				errorType: action.errorType
			};
		default:
			return state;
	}
}
