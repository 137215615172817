import { PERSISTENCE_KEYS } from './persistenceKeys';

function persist(key, value) {
	try {
		const serializedValue = JSON.stringify(value);
		localStorage.setItem(key, serializedValue);
	} catch (e) {
		// ignore error
	}
}

function load(key) {
	try {
		const serializedValue = localStorage.getItem(key);
		if (serializedValue == null) {
			return undefined;
		}
		return JSON.parse(serializedValue);
	} catch (e) {
		return undefined;
	}
}

const LOCAL_STORAGE_KEY = 'state';
export const saveState = ({
	appointmentId,
	balancePaymentAmount,
	encryptedIdentifier,
	insuranceSelection,
	locale,
	user: userState
}) =>
	persist(LOCAL_STORAGE_KEY, {
		[PERSISTENCE_KEYS.APPOINTMENT_ID]: appointmentId,
		[PERSISTENCE_KEYS.BALANCE_AMOUNT]: balancePaymentAmount,
		[PERSISTENCE_KEYS.ENCRYPTED_IDENTIFIER]: encryptedIdentifier,
		[PERSISTENCE_KEYS.INSURANCE_SELECTION]: insuranceSelection,
		[PERSISTENCE_KEYS.LOCALE]: locale,
		[PERSISTENCE_KEYS.USER_STATE]: userState
	});
export const loadState = () => load(LOCAL_STORAGE_KEY);
