// Copyright 2018-2022 NXGN Management, LLC. All Rights Reserved.

import { DATA_STATE } from '../dataState';
import { INSURANCE_ACTION_TYPES } from './actions';
import { OCR_ERROR_TYPES } from './constants';

const INITIAL_STATE = {
	dataState: DATA_STATE.NOT_INITIALIZED,
	insurance: { insuranceList: [], status: '', payOutOfPocket: false },
	insuranceFromPM: {
		dataState: DATA_STATE.NOT_INITIALIZED,
	}
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case INSURANCE_ACTION_TYPES.FETCHING:
			return {
				...state,
				dataState: DATA_STATE.FETCHING
			};
		case INSURANCE_ACTION_TYPES.FETCH_SUCCEEDED:
			const returnValue = {
				...state,
				dataState: DATA_STATE.INITIALIZED,
				insurance: action.insurance
			};
			returnValue.insurance.status = action.insurance.status;
			return returnValue;
		case INSURANCE_ACTION_TYPES.SEND_SUCCEEDED:
			return {
				...state,
				dataState: DATA_STATE.INITIALIZED
			};
		case INSURANCE_ACTION_TYPES.DELETE_INSURANCE:
			const deleteInsurance = {
				insuranceList: action.insurance,
			};
			return {
				...state,
				dataState: DATA_STATE.INITIALIZED,
				insurance: deleteInsurance,
				insuranceFromPM: { ...state.insuranceFromPM }
			};

		case INSURANCE_ACTION_TYPES.CLEAR_TIER:
		case INSURANCE_ACTION_TYPES.UPDATE_TIER:
			state.insurance.insuranceList.push(action.insurance);
			return {
				...state,
				dataState: DATA_STATE.INITIALIZED,
				insurance: state.insurance,
				insuranceFromPM: { ...state.insuranceFromPM }
			};
		case INSURANCE_ACTION_TYPES.FETCH_FAILED:
			return {
				...state,
				dataState: DATA_STATE.FETCH_FAILED,
				errorType: action.errorType
			};
		case INSURANCE_ACTION_TYPES.SENDING:
			return {
				...state,
				dataState: DATA_STATE.SENDING
			};
		case INSURANCE_ACTION_TYPES.SEND_FAILED:
			return {
				...state,
				dataState: DATA_STATE.SEND_FAILED,
				errorType: action.errorType
			};
		case INSURANCE_ACTION_TYPES.OCR_FAILED:
			return {
				...state,
				dataState: DATA_STATE.OCR_FAILED,
				errorType: OCR_ERROR_TYPES.OCR_FAILED
			};
		case INSURANCE_ACTION_TYPES.FETCH_INSURANCE_FROM_PM:
			return {
				...state,
				insuranceFromPM: {
					dataState: DATA_STATE.FETCHING
				}
			};
		case INSURANCE_ACTION_TYPES.FETCH_INSURANCE_FROM_PM_FAILED:
			return {
				...state,
				insuranceFromPM: {
					dataState: DATA_STATE.FETCH_FAILED
				}
			};
		case INSURANCE_ACTION_TYPES.FETCH_SUCCEEDED_INSURANCE_FROM_PM:
			return {
				...state,
				insuranceFromPM: {
					dataState: DATA_STATE.INITIALIZED,
					listOfInsuranceFromPM: action.payload
				}
			}
		case INSURANCE_ACTION_TYPES.PAY_OUT_OF_POCKET:
			return {
				...state,
				insurance: {
					...state.insurance
				},
				update: {
					payOutOfPocket: action.data
				}
			};
		default:
			return state;
	}
}
