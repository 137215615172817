// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import { LOCALE_ACTION_TYPES } from './actions';

const DEFAULT_LOCALE = 'en-US';
const ALTERNATIVE_LOCALE = 'es-US';
const INITIAL_STATE = { locale: DEFAULT_LOCALE, loaded: false };

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case LOCALE_ACTION_TYPES.SET:
			return { ...state, locale: action.locale };
		case LOCALE_ACTION_TYPES.SWITCH:
			return {
				...state,
				locale: state.locale === DEFAULT_LOCALE ? ALTERNATIVE_LOCALE : DEFAULT_LOCALE
			};
		default:
			return state;
	}
}

export const getLocale = localeState => localeState.locale;

export const createLoadedLocaleState = locale =>
	locale
		? {
				locale,
				loaded: true
		  }
		: INITIAL_STATE;
