// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import createFailedNetworkActionCreator from '../../utils/createFailedNetworkActionCreator';

export const PATIENT_FORMS_ACTIONS_TYPES = {
	FETCHING: 'PATIENT_FORMS/FETCHING',
	FETCH_SUCCEEDED: 'PATIENT_FORMS/FETCH_SUCCEEDED',
	FETCH_FAILED: 'PATIENT_FORMS/FETCH_FAILED',
	SENDING: 'PATIENT_FORMS/SENDING',
	SEND_SUCCEEDED: 'PATIENT_FORMS/SEND_SUCCEEDED',
	SEND_FAILED: 'PATIENT_FORMS/SEND_FAILED'
};

export function fetchingPatientForms() {
	return {
		type: PATIENT_FORMS_ACTIONS_TYPES.FETCHING
	};
}

export function fetchPatientFormsSucceeded(patientForms) {
	return {
		type: PATIENT_FORMS_ACTIONS_TYPES.FETCH_SUCCEEDED,
		patientForms
	};
}

export const fetchPatientFormsFailed = createFailedNetworkActionCreator(
	PATIENT_FORMS_ACTIONS_TYPES.FETCH_FAILED
);

export function sendingPatientForms() {
	return {
		type: PATIENT_FORMS_ACTIONS_TYPES.SENDING
	};
}

export function sendPatientFormsSucceeded(patientForms) {
	return {
		type: PATIENT_FORMS_ACTIONS_TYPES.SEND_SUCCEEDED,
		patientForms
	};
}

export const sendPatientFormsFailed = createFailedNetworkActionCreator(
	PATIENT_FORMS_ACTIONS_TYPES.SEND_FAILED
);
