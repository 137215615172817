import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from './constants/routes';
import MfLoadingPageBlocker from 'mf-react-standards/lib/exports/MfLoadingPageBlocker';

const AuthenticatedRoutes = lazy(() => import('./AuthenticatedRoutes'));
const Confirmation = lazy(() => import('./scenes/Confirmation'));
const Arrival = lazy(() => import('./scenes/Arrival'));
const Login = lazy(() => import('./scenes/Login'));
const Optout = lazy(() => import('./scenes/Optout'));
const SessionTimeout = lazy(() => import('./scenes/SessionTimeout'));
const SignedOut = lazy(() => import('./scenes/SignedOut'));

export default function() {
	return (
		<Suspense fallback={<MfLoadingPageBlocker display={true} />}>
			<Switch>
				<Route
					path="/"
					exact
					render={() => (
						<Redirect to={ROUTES.LOGIN} /> // just redirect to the login page for now, we'll add some logic based on session state later
					)}
				/>
				<Route path={ROUTES.ACTION}>
					<Confirmation />
				</Route>
				<Route path={ROUTES.CURBS}>
					<Arrival />
				</Route>
				<Route path={ROUTES.LOGIN}>
					<Login />
				</Route>
				<Route path={ROUTES.SIGNED_OUT}>
					<SignedOut />
				</Route>
				<Route path={ROUTES.SESSION_TIMEOUT}>
					<SessionTimeout />
				</Route>
				<Route path={ROUTES.OPTOUT}>
					<Optout />
				</Route>
				<Route>
					<AuthenticatedRoutes />
				</Route>
			</Switch>
		</Suspense>
	);
}
