import { ConnectedRouter } from 'connected-react-router';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import './App.scss';
import ConnectedIntlProvider from './components/ConnectedIntlProvider';
import { LONG_PRODUCT_NAME } from './constants';
import Routes from './Routes';
import store, { history } from './store';

class App extends Component {
	render() {
		document.title = LONG_PRODUCT_NAME;

		return (
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<ConnectedIntlProvider>
						<Routes />
					</ConnectedIntlProvider>
				</ConnectedRouter>
			</Provider>
		);
	}
}

export default App;
