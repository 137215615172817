// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

export const INSURANCE_SELECTION_ACTION_TYPES = {
	SELECT: 'INSURANCE/SELECT'
};

export function selectInsurance(tier, insurance) {
	return {
		type: INSURANCE_SELECTION_ACTION_TYPES.SELECT,
		tier,
		insurance
	};
}
