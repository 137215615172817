// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import { INSURANCE_SELECTION_ACTION_TYPES } from './actions';
import { GLOBAL_ACTION_TYPES } from '../actions';

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case INSURANCE_SELECTION_ACTION_TYPES.SELECT:
			return {
				selectedTier: action.tier,
				insurance: action.insurance
			};
		case GLOBAL_ACTION_TYPES.RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
}
