// Copyright 2018-2023 NXGN Management, LLC. All Rights Reserved.

import { combineReducers } from 'redux';
import tokenReducer, * as fromToken from './token/reducer';
import configReducer, * as fromConfig from './config/reducer';
import patientModeReducer, * as fromPatientMode from './patientMode/reducer';

export default combineReducers({
	config: configReducer,
	token: tokenReducer,
	patientMode: patientModeReducer
});

export const getTokenFromUserState = userState => userState.token;
export const getConfigFromUserState = userState => userState.config;
export const getPatientModeFromUserState = userState => userState.patientMode;
export const getEnabledStepKeys = userState =>
	fromConfig.getEnabledStepKeys(getConfigFromUserState(userState));
export const hasValidToken = userState => fromToken.hasValidToken(getTokenFromUserState(userState));
export const hasSignedOut = userState =>
	fromToken.hasUserSignedOut(getTokenFromUserState(userState));
export const getAllowedCardTypesFromUserState = userState =>
	fromConfig.getAllowedCardTypes(getConfigFromUserState(userState));
export const getPracticeIdFromUserState = userState =>
	fromConfig.getPracticeIdFromConfigState(getConfigFromUserState(userState));
export const getPatientModeEnabledFromUserState = userState =>
	fromPatientMode.getPatientModeEnabledFromState(getPatientModeFromUserState(userState));
export const getPracticeNameFromUserState = userState =>
	fromConfig.getPracticeNameFromConfigState(getConfigFromUserState(userState));
export const getPracticePhoneFromUserState = userState =>
	fromConfig.getPracticePhoneFromConfigState(getConfigFromUserState(userState));
export const getPracticeTimezoneFromUserState = userState =>
	fromConfig.getPracticeTimezoneFromConfigState(getConfigFromUserState(userState));
export const getPracticePatientModeCompletionMessageFromUserState = userState =>
	fromConfig.getPracticePatientModeCompletionMessageFromConfigState(
		getConfigFromUserState(userState)
	);
export const getPracticeDemographicSettings = userState =>
	fromConfig.getPracticeDemographicSettingsFromConfigState(
		getConfigFromUserState(userState)
	);
export const getPracticeImhSettings = userState =>
	fromConfig.getPracticeImhSettingsFromConfigState(
		getConfigFromUserState(userState)
	);
export const getPracticeIntegrationId = userState =>
	fromConfig.getPracticeIntegrationId(
		getConfigFromUserState(userState)
	);
export const getPracticeInstamedOpted = userState =>
	fromConfig.getPracticeInstamedOpted(
		getConfigFromUserState(userState)
	);
export const getEnabledPages = userState =>
	fromConfig.getEnabledPagesFromConfigState(getConfigFromUserState(userState));
export const getMinBalanceFromUserState = userState =>
	fromConfig.getMinBalanceFromConfigState(getConfigFromUserState(userState));
export const getPracticeInsuranceMessagesFromUserState = userState =>
	fromConfig.getPracticeInsuranceMessagesFromConfigState(getConfigFromUserState(userState));
export const getPracticeInsuranceSettingsFromUserState = userState =>
	fromConfig.getPracticeInsuranceSettingsFromConfigState(getConfigFromUserState(userState));
export const getPracticeNotifySettingsFromUserState = userState =>
	fromConfig.getPracticeNotifySettingsFromConfigState(getConfigFromUserState(userState));
