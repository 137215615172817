// Copyright 2018-2023 NXGN Management, LLC. All Rights Reserved.

import { DATA_STATE } from '../../dataState';
import { ERROR } from '../../errorTypes';
import { USER_ACTION_TYPES } from '../actions';
import { GLOBAL_ACTION_TYPES } from '../../actions';

const INITIAL_STATE = {
	dataState: DATA_STATE.NOT_INITIALIZED
};

export default function reducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case USER_ACTION_TYPES.FETCHING:
			return {
				...state,
				dataState: DATA_STATE.FETCHING
			};
		case USER_ACTION_TYPES.FETCH_SUCCEEDED:
			return {
				dataState: DATA_STATE.INITIALIZED,
				config: action.config
			};
		case USER_ACTION_TYPES.FETCH_FAILED:
			return {
				...state,
				dataState: DATA_STATE.FETCH_FAILED,
				errorType: action.errorType
			};
		case USER_ACTION_TYPES.SET_NEXTGEN_PRACTICE_CONFIG:
			return {
				...state,
				practiceConfig: action.response
			}
		case USER_ACTION_TYPES.SET_NEXTGEN_PRACTICE_CONFIG_FAILED:
			return {
				...state,
				practiceConfig: {
					dataState: DATA_STATE.FETCH_FAILED,
					errorType: action.errorType
				}
			};
		case USER_ACTION_TYPES.SET_GUEST_KEY:
			return {
				...state,
				config: {
					...state.config,
					guestKey: action.response?.sitekey
				}
			}
		case GLOBAL_ACTION_TYPES.RESET:
			return INITIAL_STATE;
		default:
			if (action.errorType === ERROR.UNAUTHORIZED) {
				return {
					dataState: DATA_STATE.NOT_INITIALIZED
				};
			}
			return state;
	}
}

export const getEnabledStepKeys = state =>
	state.config
		? Object.keys(state.config.pagesEnabled).filter(key => state.config.pagesEnabled[key])
		: [];

export const getEnabledPagesFromConfigState = state => state.config && state.config.pagesEnabled;

export const getPracticeIdFromConfigState = state =>
	state.config && state.config.practice && state.config.practice.id;

export const getPracticeNameFromConfigState = state =>
	state.config && state.config.practice && state.config.practice.name;

export const getPracticePhoneFromConfigState = state =>
	state.config && state.config.practice && state.config.practice.phone;

export const getPracticeTimezoneFromConfigState = state =>
	state.config && state.config.practice && state.config.practice.timezone;

export const getPracticePatientModeCompletionMessageFromConfigState = state =>
	state.config && state.config.practice && state.config.practice.patientModeCompletionMessage;

export const getPracticeDemographicSettingsFromConfigState = state =>
	state.config && state.config.practice && state.config.practice.demographicSettings;

export const getPracticeImhSettingsFromConfigState = state =>
	state.config && state.config.practice && state.config.practice.imhFormSettings;

export const getPracticeIntegrationId = state =>
	state.config && state.config.practice && state.config.practice.integrationId;

export const getPracticeInstamedOpted = state =>
	state.config && state.config.practice && state.config.practice.instamedOpted;

export const getPatientIdFromConfigState = state => state.config && state.config.patientId;

export const getAllowedCardTypes = state => state.config && state.config.cardTypes;

export const getMinBalanceFromConfigState = state => state.config && state.config.minBalance;

export const getPracticeInsuranceMessagesFromConfigState = state =>
	state.config && state.config.practice && state.config.practice.insuranceMessages;

export const getPracticeInsuranceSettingsFromConfigState = state =>
	state.config && state.config.practice && state.config.practice.insuranceSettings;

export const getPracticeNotifySettingsFromConfigState = (state) =>
	state.config && state.config.practice && state.config.practice.notifySettings;


