import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import throttle from 'lodash/throttle';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import api from './services/api';
import { loadState, saveState } from './services/persistence';
import { DATA_STATE } from './services/redux/dataState';
import createRootReducer, {
	createStateFromPersistedValues,
	getBalancePaymentAmountFromState,
	getEncryptedIdentifierFromState,
	getInsuranceSelectionFromState,
	getLocaleFromState,
	getSelectedAppointmentIdFromState,
	getTokenStateFromState,
	getUserStateFromState,
	hasSignedOut,
	hasValidToken
} from './services/redux/rootReducer';

export const history = createBrowserHistory();
const rootReducer = createRootReducer(history);

const enhancers = [];
const middleware = [thunk.withExtraArgument({ api }), routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
	if (typeof devToolsExtension === 'function') {
		enhancers.push(devToolsExtension());
	}
}

function getDataToPersist() {
	const state = store.getState();
	if (state) {
		const token = getTokenStateFromState(state);
		if (
			token.dataState !== DATA_STATE.FETCH_FAILED &&
			!hasSignedOut(state) &&
			hasValidToken(state)
		) {
			return {
				appointmentId: getSelectedAppointmentIdFromState(state),
				balancePaymentAmount: getBalancePaymentAmountFromState(state),
				encryptedIdentifier: getEncryptedIdentifierFromState(state),
				insuranceSelection: getInsuranceSelectionFromState(state),
				locale: getLocaleFromState(state),
				user: getUserStateFromState(state)
			};
		}
	}
	return {};
}

const composedEnhancers = compose(
	applyMiddleware(...middleware),
	...enhancers
);
const persistedState = createStateFromPersistedValues(loadState());
const store = createStore(rootReducer, persistedState, composedEnhancers);
store.subscribe(throttle(() => saveState(getDataToPersist()), 1000));

export default store;
