// Copyright 2020 NXGN Management, LLC. All Rights Reserved.

import axios from 'axios';
import { CONFIG } from '../config';

export function confirmArrival(arrivalToken) {
	const encodedToken = window.encodeURIComponent(arrivalToken);
	return axios.post(
		CONFIG.BASE_URL + `/v1/arrival_actions?token=${encodedToken}&action=CURBSCHECKIN`
	);
}

export function buttonAction(token) {
	const encodedToken = window.encodeURIComponent(token);
	return axios.post(CONFIG.BASE_URL + `/v1/arrival_actions?token=${encodedToken}&action=ARRIVAL`);
}
