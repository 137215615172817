// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import createFailedNetworkActionCreator from '../../utils/createFailedNetworkActionCreator';

export const UPCOMING_APPOINTMENTS_ACTION_TYPES = {
	FETCHING: 'USER/UPCOMING_APPOINTMENTS/FETCHING',
	FETCH_SUCCEEDED: 'USER/UPCOMING_APPOINTMENTS/FETCH_SUCCEEDED',
	FETCH_FAILED: 'USER/UPCOMING_APPOINTMENTS/FETCH_FAILED',
	SET_ENCRYPTED_IDENTIFIER: 'USER/UPCOMING_APPOINTMENTS/SET_ENCRYPTED_ID'
};

export function fetchingUpcomingAppointments() {
	return {
		type: UPCOMING_APPOINTMENTS_ACTION_TYPES.FETCHING
	};
}

export function fetchUpcomingAppointmentsSucceeded(appointments) {
	return {
		type: UPCOMING_APPOINTMENTS_ACTION_TYPES.FETCH_SUCCEEDED,
		appointments: appointments.appointments
	};
}

export const fetchUpcomingAppointmentsFailed = createFailedNetworkActionCreator(
	UPCOMING_APPOINTMENTS_ACTION_TYPES.FETCH_FAILED
);
