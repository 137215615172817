// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import createFailedNetworkActionCreator from '../../utils/createFailedNetworkActionCreator';

export const BALANCE_ACTION_TYPES = {
	REENTERING_CARD_DETAILS: 'BALANCE/REENTERING_CARD_DETAILS',
	SENDING: 'BALANCE/SENDING',
	SEND_FAILED: 'BALANCE/SEND_FAILED',
	SEND_SUCCEEDED: 'BALANCE/SEND_SUCCEEDED',
	SET_PAYMENT_AMOUNT: 'BALANCE/ADD_PAYMENT_AMOUNT',
	REMOVE_SEND_FAILED: 'BALANCE/REMOVE_SEND_FAILED'
};

export function reenteringBalanceCardDetails() {
	return {
		type: BALANCE_ACTION_TYPES.REENTERING_CARD_DETAILS
	};
}

export function sendingBalance() {
	return {
		type: BALANCE_ACTION_TYPES.SENDING
	};
}

export function balanceSucceeded() {
	return {
		type: BALANCE_ACTION_TYPES.SEND_SUCCEEDED
	};
}

export function removeSendFailed() {
	return {
		type: BALANCE_ACTION_TYPES.REMOVE_SEND_FAILED
	};
}

export const balanceFailed = createFailedNetworkActionCreator(BALANCE_ACTION_TYPES.SEND_FAILED);

export function setPaymentAmount(amount) {
	return {
		type: BALANCE_ACTION_TYPES.SET_PAYMENT_AMOUNT,
		amount
	};
}
