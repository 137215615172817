// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import { DATA_STATE } from '../dataState';
import { BALANCE_ACTION_TYPES } from './actions';
import { GLOBAL_ACTION_TYPES } from '../actions';

const INITIAL_STATE = {
	dataState: DATA_STATE.NOT_INITIALIZED
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case BALANCE_ACTION_TYPES.REENTERING_CARD_DETAILS:
			return {
				dataState: DATA_STATE.NOT_INITIALIZED
			};
		case BALANCE_ACTION_TYPES.SENDING:
			return {
				...state,
				dataState: DATA_STATE.SENDING
			};
		case BALANCE_ACTION_TYPES.SEND_SUCCEEDED:
			return { dataState: DATA_STATE.INITIALIZED };
		case BALANCE_ACTION_TYPES.SEND_FAILED:
			return {
				...state,
				dataState: DATA_STATE.SEND_FAILED,
				errorType: action.errorType
			};
		case BALANCE_ACTION_TYPES.SET_PAYMENT_AMOUNT:
			return {
				...state,
				paymentAmount: action.amount
			};
		case BALANCE_ACTION_TYPES.REMOVE_SEND_FAILED:
			return {
				...state,
				dataState: DATA_STATE.NOT_INITIALIZED
			};
		case GLOBAL_ACTION_TYPES.RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
}

export const getPaymentAmountFromBalanceState = state => state && state.paymentAmount;

export const createStateFromBalanceAmount = paymentAmount => ({
	...INITIAL_STATE,
	paymentAmount
});
