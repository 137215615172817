// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

export const GLOBAL_ACTION_TYPES = {
	RESET: 'RESET'
};

export function resetState() {
	return {
		type: GLOBAL_ACTION_TYPES.RESET
	};
}
