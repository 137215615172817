// Copyright 2018-2023 NXGN Management, LLC. All Rights Reserved.

import createFailedNetworkActionCreator from '../../utils/createFailedNetworkActionCreator';

export const APPOINTMENTS_ACTION_TYPES = {
	FETCHING: 'USER/APPOINTMENTS/FETCHING',
	FETCH_SUCCEEDED: 'USER/APPOINTMENTS/FETCH_SUCCEEDED',
	FETCH_FAILED: 'USER/APPOINTMENTS/FETCH_FAILED',
	SET_ENCRYPTED_IDENTIFIER: 'USER/APPOINTMENTS/SET_ENCRYPTED_ID',
	SELECT: 'USER/APPOINTMENTS/SELECT',
	UPDATING_DEMOGRAPHICS: 'USER/APPOINTMENTS/FETCHING_UPDATING',
	UPDATE_SUCCEEDED: 'USER/APPOINTMENTS/UPDATE_SUCCEEDED',
	UPDATE_FAILED: 'USER/APPOINTMENTS/UPDATE_FAILED',
	UPDATE_BALANCE_SUCCEEDED: 'USER/APPOINTMENTS/UPDATE_BALANCE_SUCCEEDED',
	UPDATE_COPAYMENT_SUCCEEDED: 'USER/APPOINTMENTS/UPDATE_COPAYMENT_SUCCEEDED',
	UPDATE_INSURANCE_SUCCEEDED: 'USER/APPOINTMENTS/UPDATE_INSURANCE_SUCCEEDED',
	UPDATE_INSURANCE_OCR_SUCCEEDED: 'USER/APPOINTMENTS/UPDATE_INSURANCE_OCR_SUCCEEDED',
	NGPAY_PAYMENT_SUCCESS: 'USER/APPOINTMENTS/PAYMENT_SUCCESS',
};

export function fetchingAppointment() {
	return {
		type: APPOINTMENTS_ACTION_TYPES.FETCHING,
	};
}

export function fetchAppointmentSucceeded(appointment) {
	return {
		type: APPOINTMENTS_ACTION_TYPES.FETCH_SUCCEEDED,
		appointment: appointment,
	};
}

export const fetchAppointmentFailed = createFailedNetworkActionCreator(
	APPOINTMENTS_ACTION_TYPES.FETCH_FAILED
);

export function setEncryptedIdentifier(encryptedId) {
	return {
		type: APPOINTMENTS_ACTION_TYPES.SET_ENCRYPTED_IDENTIFIER,
		encryptedId,
	};
}

export function selectAppointment(id) {
	return {
		type: APPOINTMENTS_ACTION_TYPES.SELECT,
		id,
	};
}

export function updating() {
	return {
		type: APPOINTMENTS_ACTION_TYPES.UPDATING_DEMOGRAPHICS,
	};
}

export function updateDemographicsSucceeded(appointmentId, demographics) {
	return {
		type: APPOINTMENTS_ACTION_TYPES.UPDATE_SUCCEEDED,
		appointmentId,
		demographics,
	};
}

export const updateFailed = createFailedNetworkActionCreator(
	APPOINTMENTS_ACTION_TYPES.UPDATE_FAILED
);

export function updateCopaySucceeded(appointmentId, copay) {
	return {
		type: APPOINTMENTS_ACTION_TYPES.UPDATE_COPAYMENT_SUCCEEDED,
		appointmentId,
		copay,
	};
}

export function updateBalanceSucceeded(appointmentId, balance) {
	return {
		type: APPOINTMENTS_ACTION_TYPES.UPDATE_BALANCE_SUCCEEDED,
		appointmentId,
		balance,
	};
}

export function updateInsuranceSucceeded(appointmentId, insurance) {
	return {
		type: APPOINTMENTS_ACTION_TYPES.UPDATE_INSURANCE_SUCCEEDED,
		appointmentId,
		insurance,
	};
}

export function updateInsuranceOcrSucceeded(appointmentId, ocrData) {
	return {
		type: APPOINTMENTS_ACTION_TYPES.UPDATE_INSURANCE_OCR_SUCCEEDED,
		appointmentId,
		ocrData,
	};
}

export function ngpayPaymentSuccess(response) {
	return {
		type: APPOINTMENTS_ACTION_TYPES.NGPAY_PAYMENT_SUCCESS,
		response,
	};
}
