// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

export const LOCALE_ACTION_TYPES = {
	SET: 'LOCALE/SET',
	SWITCH: 'LOCALE/SWITCH'
};

export function setLocale(locale) {
	return {
		type: LOCALE_ACTION_TYPES.SET,
		locale
	};
}

export function switchLocale() {
	return {
		type: LOCALE_ACTION_TYPES.SWITCH
	};
}
