// Copyright 2018-2023 NXGN Management, LLC. All Rights Reserved.

import axios from 'axios';

import { CONFIG } from '../config';
import { createAxiosConfigWithAuthHeader } from '../utils';

export function putDemographics(authToken, practiceId, pmPatientId, pmAppointmentId, demographics) {
	return axios.put(
		CONFIG.BASE_URL +
			`/v1/guestSessions/practices/${practiceId}/patients/${pmPatientId}/appointments/${pmAppointmentId}/demographics`,
		demographics,
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function putPatientForms(authToken, practiceId, pmPatientId, pmAppointmentId, forms) {
	return axios.put(
		CONFIG.BASE_URL +
			`/v1/guestSessions/practices/${practiceId}/patients/${pmPatientId}/appointments/${pmAppointmentId}/forms`,
		{ forms: forms },
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function getAppointments(authToken, practiceId, pmPatientId, startDate) {
	return axios.get(
		CONFIG.BASE_URL +
			`/v1/guestSessions/practices/${practiceId}/patients/${pmPatientId}/appointments?startDate=${startDate}`,
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function getSelectedAppointment(authToken, practiceId, pmPatientId, pmAppointmentId) {
	return axios.get(
		CONFIG.BASE_URL +
			`/v1/guestSessions/practices/${practiceId}/patients/${pmPatientId}/appointments/${pmAppointmentId}`,
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function putInsurance(authToken, practiceId, pmPatientId, pmAppointmentId, insurance) {
	return axios.put(
		CONFIG.BASE_URL +
			`/v1/guestSessions/practices/${practiceId}/patients/${pmPatientId}/appointments/${pmAppointmentId}/insurance`,
		insurance,
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function postBalance(authToken, practiceId, pmPatientId, pmAppointmentId, balance) {
	return axios.post(
		CONFIG.BASE_URL +
			`/v1/guestSessions/practices/${practiceId}/patients/${pmPatientId}/appointments/${pmAppointmentId}/balance/pay`,
		balance,
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function postSkipBalance(authToken, practiceId, pmPatientId, pmAppointmentId) {
	return axios.post(
		CONFIG.BASE_URL +
			`/v1/guestSessions/practices/${practiceId}/patients/${pmPatientId}/appointments/${pmAppointmentId}/balance/skip`,
		{},
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function postCopay(authToken, practiceId, pmPatientId, pmAppointmentId, copay) {
	return axios.post(
		CONFIG.BASE_URL +
			`/v1/guestSessions/practices/${practiceId}/patients/${pmPatientId}/appointments/${pmAppointmentId}/copay/pay`,
		copay,
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function postSkipCopay(authToken, practiceId, pmPatientId, pmAppointmentId) {
	return axios.post(
		CONFIG.BASE_URL +
			`/v1/guestSessions/practices/${practiceId}/patients/${pmPatientId}/appointments/${pmAppointmentId}/copay/skip`,
		{},
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function skipBilling(authToken, practiceId, pmPatientId, pmAppointmentId, pmBalance) {
	return axios.post(
		CONFIG.BASE_URL +
			`/v1/guestSessions/practices/${practiceId}/patients/${pmPatientId}/appointments/${pmAppointmentId}/billing/skip?pmBalance=${pmBalance}`,
		{},
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function getRequiredForms(authToken, practiceId, pmPatientId, pmAppointmentId) {
	return axios.get(
		`${CONFIG.BASE_URL}/v1/guestSessions/practices/${practiceId}/patients/${pmPatientId}/appointments/${pmAppointmentId}/requiredForms`,
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function getListOfInsuranceFromPM(authToken, pmPatientId, practiceId, integrationId) {
	return axios.get(
		`${CONFIG.BASE_URL}/v1/guestSessions/practices/${practiceId}/integrations/${integrationId}/patients/${pmPatientId}/insurances`,
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function getOcrData(authToken, practiceId, pmAppointmentId, pmPatientId, images) {
	const formData = new FormData();

	formData.append('cardImageBack', images.front);
	formData.append('cardImageFront', images.back);
	formData.append('pmAppointmentId', pmAppointmentId);
	formData.append('pmPatientId', pmPatientId);
	formData.append('practiceId', practiceId);

	return axios.post(
		`${CONFIG.BASE_URL}/v1/guestSessions/practice/${practiceId}/insurance/ocr`,
		formData,
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function getRefreshToken(authToken, practiceId, pmPatientId) {
	return axios.get(
		`${CONFIG.BASE_URL}/v1/guestSessions/v1/practices/${practiceId}/patients/${pmPatientId}/refreshToken`,
		createAxiosConfigWithAuthHeader(authToken)
	);
}

export function savePaymentStatus(authToken, practiceId, pmPatientId, pmAppointmentId, billing) {
	return axios.post(
		CONFIG.BASE_URL +
			`/v1/guestSessions/practices/${practiceId}/patients/${pmPatientId}/appointments/${pmAppointmentId}/billing/save`,
		billing,
		createAxiosConfigWithAuthHeader(authToken)
	);
}
