// Copyright 2018-2021 NXGN Management, LLC. All Rights Reserved.

export const INSURANCE_TYPE = {
	IMAGE: 'IMAGE',
	INFO: 'INFO',
	OCR: 'OCR',
	OTHER: 'OTHER'
};

export const INSURANCE_TIERS = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	TERTIARY: 'tertiary',
	SUBHEADING: 'subHeading'
};

export const INSURANCE_IMAGE_TYPE = {
	BACK: 'back',
	FRONT: 'front'
};

export const INSURANCE_ERROR_TYPES = {
	SIZE_EXCEEDED: 'fileExceeded',
	FILE_TYPE_NOT_SUPPORTED: 'unsupportedFileType'
};

export const OCR_ERROR_TYPES = {
	OCR_FAILED: 'ocrFailed'
};

export const INSURANCE_TIER_STATUS = {
	EDITED: 'EDITED',
	ADDED: 'ADDED',
	REMOVED: 'REMOVED',
	CONFIRMED: 'CONFIRMED',
	PENDING: 'PENDING'
};
