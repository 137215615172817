// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import { CONFIRMATION_ACTION_TYPES } from './actions';
import { DATA_STATE } from '../dataState';

const INITIAL_STATE = {
	dataState: DATA_STATE.NOT_INITIALIZED
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case CONFIRMATION_ACTION_TYPES.CONFIRMING_APPOINTMENT:
			return {
				...state,
				dataState: DATA_STATE.SENDING
			};
		case CONFIRMATION_ACTION_TYPES.APPOINTMENT_CONFIRMATION_SUCCESS:
			return {
				...state,
				dataState: DATA_STATE.INITIALIZED,
				...action.data
			};

		case CONFIRMATION_ACTION_TYPES.APPOINTMENT_CONFIRMATION_FAILURE:
			return {
				...state,
				dataState: DATA_STATE.SEND_FAILED
			};
		default:
			return state;
	}
}
