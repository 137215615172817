// Copyright 2018-2023 NXGN Management, LLC. All Rights Reserved.

import { APPOINTMENTS_ACTION_TYPES } from './actions';
import { DATA_STATE } from '../dataState';
import { GLOBAL_ACTION_TYPES } from '../actions';

const INITIAL_STATE = {
	dataState: DATA_STATE.NOT_INITIALIZED,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case APPOINTMENTS_ACTION_TYPES.SELECT:
			if (action.id === state.selectedAppointmentId) {
				return state;
			}
			// Invalidate appointments store. Next fetch will be for this appt ID.
			return {
				dataState: DATA_STATE.NOT_INITIALIZED,
				selectedAppointmentId: action.id,
			};
		case APPOINTMENTS_ACTION_TYPES.FETCHING:
		case APPOINTMENTS_ACTION_TYPES.UPDATING_DEMOGRAPHICS:
			return {
				...state,
				dataState: DATA_STATE.FETCHING,
			};
		case APPOINTMENTS_ACTION_TYPES.UPDATE_FAILED:
		case APPOINTMENTS_ACTION_TYPES.FETCH_FAILED:
			return {
				...state,
				dataState: DATA_STATE.FETCH_FAILED,
				errorType: action.errorType,
			};
		case APPOINTMENTS_ACTION_TYPES.FETCH_SUCCEEDED:
			return {
				dataState: DATA_STATE.INITIALIZED,
				appointment: action.appointment,
				encryptedIdentifier: state.encryptedIdentifier,
				selectedAppointmentId: state.selectedAppointmentId,
			};
		case APPOINTMENTS_ACTION_TYPES.UPDATE_SUCCEEDED:
			return {
				dataState: DATA_STATE.INITIALIZED,
				appointment: {
					...state.appointment,
					precheckAppointment: {
						...state.appointment.precheckAppointment,
						patientDemographics: action.demographics,
					},
				},
				encryptedIdentifier: state.encryptedIdentifier,
				selectedAppointmentId: state.selectedAppointmentId,
			};
		case APPOINTMENTS_ACTION_TYPES.SET_ENCRYPTED_IDENTIFIER:
			return {
				...state,
				encryptedIdentifier: action.encryptedId,
			};
		case APPOINTMENTS_ACTION_TYPES.UPDATE_COPAYMENT_SUCCEEDED:
			return {
				...state,
				dataState: DATA_STATE.INITIALIZED,
				appointment: {
					...state.appointment,
					precheckAppointment: {
						...state.appointment.precheckAppointment,
						copay: action.copay,
					},
				},
			};
		case APPOINTMENTS_ACTION_TYPES.UPDATE_BALANCE_SUCCEEDED:
			return {
				...state,
				dataState: DATA_STATE.INITIALIZED,
				appointment: {
					...state.appointment,
					precheckAppointment: {
						...state.appointment.precheckAppointment,
						balance: action.balance,
					},
				},
			};
		case APPOINTMENTS_ACTION_TYPES.UPDATE_INSURANCE_OCR_SUCCEEDED:
			return {
				...state,
				dataState: DATA_STATE.INITIALIZED,
				appointment: {
					...state.appointment,
					precheckAppointment: {
						...state.appointment.precheckAppointment,
						ocrData: action.ocrData,
					},
				},
			};
		case APPOINTMENTS_ACTION_TYPES.UPDATE_INSURANCE_SUCCEEDED:
			return {
				...state,
				dataState: DATA_STATE.INITIALIZED,
				appointment: {
					...state.appointment,
					precheckAppointment: {
						...state.appointment.precheckAppointment,
						insurance: action.insurance,
					},
				},
			};
		case APPOINTMENTS_ACTION_TYPES.NGPAY_PAYMENT_SUCCESS:
			return {
				...state,
				appointment: {
					...state.appointment,
					precheckAppointment: action.response,
				},
			};
		case GLOBAL_ACTION_TYPES.RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
}

export const getSelectedAppointment = (state) =>
	state.appointment ? state.appointment : undefined;
export const getEncryptedIdentifier = (state) => state.encryptedIdentifier;
export const getPrecheckAppointment = (state) =>
	state.appointment && state.appointment.precheckAppointment
		? state.appointment.precheckAppointment
		: null;
export const getPatientProvidedInsurance = (state) =>
	state && state && state.precheckAppointment && state.precheckAppointment.insurance
		? state.precheckAppointment.insurance
		: {};

// persistence
export const getSelectedAppointmentId = (state) =>
	state && state.selectedAppointmentId ? state.selectedAppointmentId : null;
export const createStateFromSelectedAppointmentId = (
	selectedAppointmentId,
	encryptedIdentifier
) => ({
	...INITIAL_STATE,
	encryptedIdentifier,
	selectedAppointmentId,
});
