// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

export default function createFailedNetworkActionCreator(type) {
	return function(errorType) {
		return {
			type,
			errorType
		};
	};
}
