// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

export const CREDIT_CARDS_ACTION_TYPES = {
	ADD: 'CREDIT_CARDS/ADD',
	SELECT: 'CREDIT_CARDS/SELECT',
	REMOVE: 'CREDIT_CARDS/REMOVE'
};

export function addCreditCard(card) {
	return {
		type: CREDIT_CARDS_ACTION_TYPES.ADD,
		card
	};
}

export function selectCreditCard(cardNumber) {
	return {
		type: CREDIT_CARDS_ACTION_TYPES.SELECT,
		cardNumber
	};
}

export function removeCreditCard(selectedCard) {
	return {
		type: CREDIT_CARDS_ACTION_TYPES.REMOVE,
		selectedCard
	};
}
