// Copyright 2018-2022 NXGN Management, LLC. All Rights Reserved.

import createFailedNetworkActionCreator from '../../utils/createFailedNetworkActionCreator';

export const INSURANCE_ACTION_TYPES = {
	CLEAR_TIER: 'INSURANCE/CLEAR_TIER',
	FETCHING: 'INSURANCE/FETCHING',
	FETCH_SUCCEEDED: 'INSURANCE/FETCH_SUCCEEDED',
	FETCH_FAILED: 'INSURANCE/FETCH_FAILED',
	SENDING: 'INSURANCE/SENDING',
	SEND_FAILED: 'INSURANCE/SEND_FAILED',
	SEND_SUCCEEDED: 'INSURANCE/SEND_SUCCEEDED',
	UPDATE_TIER: 'INSURANCE/UPDATE_TIER',
	OCR_FAILED: 'INSURANCE/FAILED',
	DELETE_INSURANCE: 'INSURANCE/DELETE',
	FETCH_INSURANCE_FROM_PM: 'INSURANCE/FETCHING_INSURANCE_FROM_PM',
	FETCH_SUCCEEDED_INSURANCE_FROM_PM: 'INSURANCE/FETCH_SUCCEEDED_INSURANCE_FROM_PM',
	FETCH_INSURANCE_FROM_PM_FAILED:'INSURANCE/FETCH_INSURANCE_FROM_PM_FAILED',
	PAY_OUT_OF_POCKET: 'INSURANCE/PAY_OUT_OF_POCKET',
};

export function fetchingInsurance() {
	return {
		type: INSURANCE_ACTION_TYPES.FETCHING
	};
}

export const fetchInsuranceFailed = createFailedNetworkActionCreator(
	INSURANCE_ACTION_TYPES.FETCH_FAILED
);

export function fetchInsuranceSucceeded(insurance) {
	return {
		type: INSURANCE_ACTION_TYPES.FETCH_SUCCEEDED,
		insurance
	};
}

export function sendingInsurance() {
	return {
		type: INSURANCE_ACTION_TYPES.SENDING
	};
}

export const sendInsuranceFailed = createFailedNetworkActionCreator(
	INSURANCE_ACTION_TYPES.SEND_FAILED
);

export const ocrFailed = createFailedNetworkActionCreator(INSURANCE_ACTION_TYPES.OCR_FAILED);

export function sendInsuranceSucceeded() {
	return {
		type: INSURANCE_ACTION_TYPES.SEND_SUCCEEDED
	};
}

export function updateInsuranceTier(insurance) {
	return {
		type: INSURANCE_ACTION_TYPES.UPDATE_TIER,
		insurance
	};
}

export function deleteInsuranceTier(insurance) {
	return {
		type: INSURANCE_ACTION_TYPES.DELETE_INSURANCE,
		insurance
	};
}

export function clearInsuranceTier(insurance) {
	return {
		type: INSURANCE_ACTION_TYPES.CLEAR_TIER,
		insurance
	};
}

export function fetchInsuranceListFromPM() {
	return {
		type: INSURANCE_ACTION_TYPES.FETCH_INSURANCE_FROM_PM
	}
}

export function fetchInsuranceListFromPMFailed() {
	return {
		type: INSURANCE_ACTION_TYPES.FETCH_INSURANCE_FROM_PM_FAILED
	}
}

export function fetchSucceededInsuranceListFromPM(insurance) {
	return {
		type: INSURANCE_ACTION_TYPES.FETCH_SUCCEEDED_INSURANCE_FROM_PM,
		payload: insurance.items
	}
}

export function payOutOfPocketInsurance(payOutOfPocketFlag) {
	return {
		type: INSURANCE_ACTION_TYPES.PAY_OUT_OF_POCKET,
		data: payOutOfPocketFlag
	};
}
