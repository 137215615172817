// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

export const DATA_STATE = {
	NOT_INITIALIZED: 'NOT_INITIALIZED',
	FETCHING: 'FETCHING',
	SENDING: 'SENDING',
	INITIALIZED: 'INITIALIZED',
	FETCH_FAILED: 'FETCH_FAILED',
	SEND_FAILED: 'SEND_FAILED',
	SIGNED_OUT: 'SIGNED_OUT',
	OCR_FAILED: 'OCR_FAILED'
};
