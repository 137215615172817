// Copyright 2018-2023 NXGN Management, LLC. All Rights Reserved.

// all methods calling APIs will be located here
// the idea is to structure endpoints into separate files and then re-import them here so they
// can be used as a single object
import * as appointmentActions from './endpoints/appointmentActions';
import * as appointments from './endpoints/appointments';
import * as guests from './endpoints/guests';
import * as optout from './endpoints/optout';
import * as practiceSettings from './endpoints/practiceSettings';
import * as arrivalActions from './endpoints/arrivalActions';
import * as nextgenPractice from './endpoints/nextgenPractice';
import { interceptor } from './endpoints/interceptor';

export default {
	appointmentActions,
	appointments,
	guests,
	nextgenPractice,
	optout,
	practiceSettings,
	arrivalActions,
};
interceptor();
