// Copyright 2018-2020 NXGN Management, LLC. All Rights Reserved.

import { OPTOUT_ACTION_TYPES } from './actions';
import { DATA_STATE } from '../dataState';

const INITIAL_STATE = {
	dataState: DATA_STATE.NOT_INITIALIZED
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case OPTOUT_ACTION_TYPES.REMOVING_EMAIL:
			return {
				...state,
				dataState: DATA_STATE.SENDING
			};
		case OPTOUT_ACTION_TYPES.REMOVED_EMAIL_SUCCESS:
			return {
				...state,
				dataState: DATA_STATE.INITIALIZED
			};

		case OPTOUT_ACTION_TYPES.REMOVED_EMAIL_FAILURE:
			return {
				...state,
				dataState: DATA_STATE.SEND_FAILED
			};
		default:
			return state;
	}
}
